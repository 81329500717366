import { render, staticRenderFns } from "./ic-corporate-fare.vue?vue&type=template&id=6a1b1291&scoped=true"
import script from "./ic-corporate-fare.vue?vue&type=script&lang=js"
export * from "./ic-corporate-fare.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a1b1291",
  null
  
)

export default component.exports