<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 21V3H12V7H22V21H2ZM4 19H10V17H4V19ZM4 15H10V13H4V15ZM4 11H10V9H4V11ZM4 7H10V5H4V7ZM12 19H20V9H12V19ZM14 13V11H18V13H14ZM14 17V15H18V17H14Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcCorporateFare',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped></style>
